import React from "react"
import { navigate } from "gatsby"
import { Box, Button } from "@chakra-ui/react"
import Video from "../video"

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: { base: "100%", md: "100%", lg: "655px" },
    width: "100%",
    px: { base: "24px", md: "24px", lg: "10%" },
    marginTop: { base: "40px", md: "40px", lg: "0px" },
    marginBottom: { base: "63px", md: "63px", lg: "80.84px" },
  },
  button: {
    my: "44px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: { base: "100%", md: "100%", lg: "35%" },
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  video: {
    maxWidth: "883px",
    height: { base: "auto", md: "auto", lg: "494.16px" },
    width: "100%",
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const AboutVideo = () => (
  <Box sx={styles.box}>
    <Box sx={styles.video}>
      <Video video="https://player.vimeo.com/video/345040330?h=8a65113273" />
    </Box>
    <Button sx={styles.button} onClick={navigateToContactUs}>
      Schedule a Meeting
    </Button>
  </Box>
)

export default AboutVideo
