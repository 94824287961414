import React from "react"
import { Box, Flex, Link, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "100%" },
    mt: { base: "100px", md: "100px", lg: "0px" },
    mb: "100px",
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "430px" },
    height: { base: "100%", md: "100%", lg: "312px" },
    marginTop: { base: "16px", md: "16px", lg: "0px" },
  },
  flex: {
    flexDirection: { base: "column-reverse", md: "column reverse", lg: "row" },
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    px: "24px",
  },

  image: {
    height: { base: "auto", md: "auto", lg: "auto" },
    maxWidth: "658px",
    width: "100%",
    marginLeft: { base: "0px", md: "0px", lg: "24px" },
  },
  link: {
    color: "#3399FF",
    _hover: {
      color: "#3399FF",
    },
  },
  text: {
    lineHeight: "24px",
    fontWeight: "400px",
  },
}

const AboutContent = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles.flex}>
        <Box sx={styles["left-box"]}>
          <Text sx={styles.text}>
            Founded in 2014, Avant-garde Health partners with leading health
            care providers committed to improving the value of care.{" "}
            <Link sx={styles.link} as={GatsbyLink} to={"/caremeasurement/"}>
              Avant-garde's software solutions
            </Link>{" "}
            generate actionable insights that enable health care providers to
            understand and improve their costs and outcomes across the care
            continuum.
            <Text sx={{ paddingBottom: "8px" }}></Text>
            Avant-garde Health was born out of the Harvard Business School’s{" "}
            <Link
              sx={styles.link}
              href="https://www.isc.hbs.edu/health-care/value-based-health-care/Pages/default.aspx"
              isExternal
            >
              value-based health care delivery research
            </Link>
            , led by Professors Michael Porter and Robert Kaplan. Our{" "}
            <Link sx={styles.link} as={GatsbyLink} to={"/careers/"}>
              growing team
            </Link>{" "}
            is backed by a group of leading investors, and we are dedicated to
            transforming health care delivery for decades to come.
          </Text>
        </Box>
        <Box sx={styles.image}>
          <StaticImage
            alt="About Us"
            src="../../images/about-us/about-us-b.png"
            placeholder="blurred"
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default AboutContent
